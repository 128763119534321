#blog-container {
    background-color: #4ac4dd;
}

#nav-divider {
    height: 2px;
    width: 50%;
    opacity: 0.2;
    border-radius: 100px;
}

#blog-card {
    width: 40rem;
}

@media only screen and (max-width: 735px) {
    #blog-card {
      width: 30rem;
    }
  }
  @media only screen and (max-width: 520px) {
    #blog-card {
      width: 25rem;
    }
  }
  @media only screen and (max-width: 415px) {
    #blog-card {
      width: 20rem;
    }
  }