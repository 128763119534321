#program-container {
    min-height: 20rem;
    background-image: linear-gradient(#fff, #4ac4dd);
    color: black;
}

@media only screen and (max-width: 520px) {
    #program-container {
      margin-bottom: -5rem;
    }
  }