#nav-container,
#nav {
    background-color: #fff;
}

.nav-link {
    color: black;
    font-size: 17px;
}

.navbar-toggler { 
    background-color: #9ae1ef;
}
.nav-link:active,
.nav-link:hover {
    color: #ff0080;
}

#nav-divider {
    height: 2px;
    width: 90%;
    opacity: 0.2;
    border-radius: 100px;
}
