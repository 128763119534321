#gps-container {
    background-image: url('../../images/GPSlogo.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30rem;
    height: 30rem;
}

#gps-logo {
    max-width: 30rem;
    max-height: 30rem;
}

@media only screen and (max-width: 580px) {
    #gps-logo {
      width: 20rem;
    }
  }