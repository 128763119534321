#footer {
    color: #fff;
    height: 7.5rem;
    background-image: linear-gradient(white, #137da5);
    position: relative;
}

#footer-container {
    margin-top: 5rem;
    position: absolute;
    bottom: 0px;
}