@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-size: 20px;
  font-family: 'Abel', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#page {
  min-height: 100vh;
}

#heading {
  font-size: 3rem;
  font-family: 'Abel', sans-serif;
  text-transform: uppercase;
}
#sub-heading {
  font-family: 'Abel', sans-serif;
}

p {
  font-size: 20px;
  font-family: 'Abel', sans-serif;
}

.bodyFont {
  font-family: 'Abel', sans-serif;
}
