#hero-container {
    height: 35rem;
    width: 100%;
    background-image: url('../../images/hero2.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#hero-container2 {
    height: 30rem;
    width: 100%;
    background-image: url('../../images/membership2.jpeg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#hero-container3 {
    height: 30rem;
    width: 100%;
    background-image: url('../../images/donation.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


